import { render, staticRenderFns } from "./FullCalendarComponent.vue?vue&type=template&id=4675f379&scoped=true&"
import script from "./FullCalendarComponent.vue?vue&type=script&lang=ts&"
export * from "./FullCalendarComponent.vue?vue&type=script&lang=ts&"
import style0 from "./FullCalendarComponent.vue?vue&type=style&index=0&id=4675f379&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4675f379",
  null
  
)

export default component.exports