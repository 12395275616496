




















































































































































































































































































import { Component, Prop } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import FileResource from '@/models/graphql/FileResource';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import SendConnectionRequestModal from '@/components/modals/SendConnectionRequestModal.vue';
import { deepGet } from '@/utils/ObjectHelpers';
import ConnectionStatusType from '@/utils/enums/ConnectionStatusType';
import GroupType from '@/utils/enums/chat/GroupType';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import SubscriptionEvent from '@/utils/types/SubscriptionEvent';
import Notification from '@/models/graphql/Notification';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { addHours } from 'date-fns';
import ActionRule from '@/utils/enums/ActionRule';
import ActionRuleService from '@/services/ActionRuleService';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import Event from '@/utils/types/Event';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { mixins } from 'vue-class-component';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import useTestDataAttribute from '@/utils/TestDataAttribute';
import BaseCardWidget from '@/components/cards/BaseCardWidget.vue';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import EntityType from '@/utils/enums/EntityType';
import CrmUserCardButtons from '@/components/cards/CrmUserCardButtons.vue';
import PillComponent from '@/components/pill/PillComponent.vue';
import Country from '@/models/graphql/Country';

const communityUserConnectionStore = namespace('CommunityUserConnectionStore');
const chatStore = namespace('ChatDispatcherStore/ChatStore');
const agendaStore = namespace('AgendaStore');

@Component({
  methods: { useTestDataAttribute },
  components: {
    SendConnectionRequestModal,
    ConfirmModal,
    PillComponent,
    CrmUserCardButtons,
    AvatarSoloWidget,
  },
  inheritAttrs: false,
})
/* eslint-disable no-underscore-dangle */
export default class CrmUserCardWidget extends mixins(VueBaseWidget, VueBaseNotify, BaseCardWidget) {
  @Prop({
    required: true,
    default: '',
  })
  readonly uid!: string;

  @Prop({
    required: true,
    default: 0,
  })
  readonly id!: number;

  @Prop({
    required: true,
    default: '',
  })
  readonly meetingRequestUid!: string;

  @Prop({
    required: true,
    default: '',
  })
  readonly name!: string;

  @Prop({
    required: true,
    default: '',
  })
  readonly firstName!: string;

  @Prop({
    required: true,
    default: '',
  })
  readonly lastName!: string;

  @Prop({
    required: false,
    default: null,
  })
  readonly jobTitle!: string;

  @Prop({
    required: false,
    default: null,
  })
  readonly employerName!: string;

  @Prop({
    required: false,
    default: null,
  })
  readonly profilePrivacyMode!: string;

  @Prop({
    required: false,
    default: null,
  })
  readonly pictureFileResource!: FileResource;

  @Prop({
    required: false,
    default: null,
  })
  readonly country!: Country;

  @Prop({
    required: false,
    default: null,
  })
  readonly _ourConnection!: CommunityUserConnection;

  @Prop({
    required: false,
    default: null,
  })
  readonly _connections!: CommunityUser[];

  @Prop({
    required: false,
    default: null,
  })
  readonly _connectionCount!: number;

  @Prop({
    required: false,
    default: null,
  })
  readonly companyRoles!: CompanyUserRole[];

  @Prop({ default: false })
  readonly _isRecommendedForMe!: boolean;

  @Prop({ default: null })
  readonly cardRoute!: string;

  @Prop({ default: 'DependencyWidgetStore' })
  readonly context!: string;

  @Prop({
    required: false,
    default: true,
  })
  readonly displayRecommendedBadge!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  readonly _actions!: { key: string; value: string }[];

  @Prop({
    required: false,
    default: null,
  })
  protected readonly prefix!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  protected readonly suffix!: string | null;

  @Prop({
    required: false,
    default: false,
  })
  protected readonly isScheduled!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  protected readonly isArchived!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  protected readonly _ourSharedInterestsCompanyCount!: number;

  @Prop({
    required: false,
    default: 'user',
  })
  protected readonly cardContext!: string;

  @Prop({
    required: false,
    default: true,
  })
  protected readonly withBorderTop!: boolean;

  @Prop({
    required: false,
    default: '',
  })
  protected readonly linkClasses!: string;

  @Prop({
    required: false,
    default: 0,
  })
  protected readonly _commentCount!: number;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  // eslint-disable-next-line no-underscore-dangle
  private ourConnection: CommunityUserConnection | undefined | null = this._ourConnection;

  @chatStore.Action
  private openSelectedConversation!: (payload: {
    myUid: string;
    users: CommunityUser[];
    groupType: string[];
  }) => Promise<void>;

  @communityUserConnectionStore.Action
  private fullyConnect!: (payload: {
    connectionArgs: object; newConversationArgs: object;
  }) => Promise<CommunityUserConnection | undefined>;

  @communityUserConnectionStore.Action
  private fullyDisconnect!: (payload: {
    uid: string;
    messageLookupArgs: object;
    toastUserFullName: string;
  }) => Promise<CommunityUserConnection | undefined>;

  @communityUserConnectionStore.Action
  private fullyAcceptConnection!: (payload: {
    uid: string; messageLookupArgs: object; toastUserFullName: string;
  }) => Promise<CommunityUserConnection | undefined>;

  @communityUserConnectionStore.Action
  private fullyDeclineConnection!: (payload: {
    uid: string; messageLookupArgs: object; toastUserFullName: string;
  }) => Promise<CommunityUserConnection | undefined>;

  @agendaStore.Mutation
  private setEventEdited!: (e: Partial<Event>) => void;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  private connectionStatusType = ConnectionStatusType;

  private FileResourceHelper = FileResourceHelper;

  private ActionRule = ActionRule;

  private ActionRuleService = ActionRuleService;

  private FeatureKeys = FeatureKeys;

  private get buttonsEnabled(): boolean {
    if (!this.authUser || this.cardContext === 'meetingRequest') {
      return true;
    }
    return this.authUser.uid !== this.uid;
  }

  private get isProfileVisible(): boolean {
    const user = CommunityUser.hydrate({
      uid: this.uid,
      profilePrivacyMode: this.profilePrivacyMode,
      _ourConnection: this.ourConnection,
    });

    return user.isProfileVisible || (!!user._ourConnection
      && (user._ourConnection.linkState === ConnectionStatusType.ACCEPTED
        || user._ourConnection.linkState === ConnectionStatusType.INVITED));
  }

  private get jobTitleEmployer(): string {
    const jobTitleEmployer: string[] = [];
    if (!CommunityUser.hydrate({
      uid: this.uid,
      profilePrivacyMode: this.profilePrivacyMode,
      _ourConnection: this.ourConnection,
    }).isProfileVisible) {
      return this.$t('app.my-profile.is-private') as string;
    }

    if (this.jobTitle) {
      jobTitleEmployer.push(this.jobTitle as string);
    }

    if (this.companyRoles && this.companyRoles.length > 0) {
      const selectedCompanyUserRole = (this.companyRoles as unknown as CompanyUserRole[])
        .find((companyRole: CompanyUserRole) => companyRole.defaultCompany);
      if (selectedCompanyUserRole
        && selectedCompanyUserRole.company
        && selectedCompanyUserRole.company.name) {
        jobTitleEmployer.push(`${selectedCompanyUserRole.company.name}`);
      } else {
        jobTitleEmployer.push(`${this.employerName}`);
      }
    } else if (this.employerName) {
      jobTitleEmployer.push(`${this.employerName}`);
    }
    return jobTitleEmployer.join(` ${this.$tc('actions.at', 0, { employer: '' })} `);
  }

  private get handleBaseUrl(): string | null {
    if (this.cardRoute) {
      let r = this.cardRoute;
      const matches = this.cardRoute.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '')
            .trim();
          const newValue = deepGet(this.$props, prop);
          if (newValue) {
            r = r.replaceAll(m, newValue);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        return r;
      }
      if (r[r.length - 1] === '/') {
        return `${r}${this.uid}`;
      }
      return `${r}/${this.uid}`;
    }
    return null;
  }

  private get countryFlag(): string | null {
    if (this.country
      && 'iso' in this.country
      && this.country.iso) {
      return this.country.iso;
    }
    return null;
  }

  private get hasConnectionFeature(): boolean {
    return this.isFeatureActivated(FeatureKeys.COMMUNITY_CONNECTIONS_FEATURE);
  }

  private get getFullPrefixedName(): string {
    let fullName = '';
    if (this.prefix) {
      fullName = `${this.prefix}`;
    }
    if (this.firstName) {
      const separator = fullName ? ' ' : '';
      fullName = `${fullName}${separator}${this.firstName}`;
    }
    if (this.lastName) {
      const separator = fullName ? ' ' : '';
      fullName = `${fullName}${separator}${this.lastName}`;
    }
    if (this.suffix) {
      const separator = fullName ? ', ' : '';
      fullName = `${fullName}${separator}${this.suffix}`;
    }
    return fullName.trim();
  }

  created(): void {
    this.notifyEvents = [NotificationEventType.CONNECTION_REQUEST];
  }

  protected notificationCallback(event: SubscriptionEvent): void {
    if (event.type === NotificationEventType.CONNECTION_REQUEST) {
      const notification = Notification.hydrate(event.data);
      if (notification
        && notification.initiator
        && (notification.initiator as CommunityUser).uid === this.uid
        && notification.notifiedUsers.findIndex((n) => n.uid === this.authUser.uid) > -1) {
        const connection = notification.triggered as unknown as CommunityUserConnection;
        if (connection
          && connection.linkState
          && [ConnectionStatusType.INVITED, ConnectionStatusType.ACCEPTED]
            .includes(connection.linkState)) {
          this.ourConnection = connection;
        } else {
          this.ourConnection = null;
        }
      }
    }
  }

  private isConnectionAccepted(): boolean {
    return !!this.ourConnection && this.ourConnection.linkState === ConnectionStatusType.ACCEPTED;
  }

  private isFeatureActivated(code: FeatureKeys): boolean {
    return this.featureByKey(code) && this.featureByKey(code).enabled;
  }

  private openConversation(): void {
    const auth = (({
      uid,
      firstName,
      lastName,
      pictureFileResource,
    }) => ({
      uid,
      firstName,
      lastName,
      pictureFileResource,
    }))(this.authUser) as CommunityUser;
    this.openSelectedConversation({
      myUid: this.authUser.uid,
      users: [auth, {
        uid: this.uid,
        firstName: this.firstName,
        lastName: this.lastName,
        pictureFileResource: this.pictureFileResource,
      } as CommunityUser],
      groupType: [GroupType.DIRECT],
    });
  }

  private toggleModal(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (this.ourConnection
      && this.ourConnection.linkState === this.connectionStatusType.INVITED) {
      return;
    }
    if (this.ourConnection) {
      this.$bvModal.show(`user-card-remove-connection-${this.uid}`);
    } else {
      this.$bvModal.show(`user-card-issue-connection-request-${this.meetingRequestUid}`);
    }
  }

  private onMainCardClick(): void {
    if (this.cardContext === 'user') {
      this.onCardClick(this.handleBaseUrl);
    } else {
      this.$emit('on-click', this.uid);
    }
  }

  private onAccept(): void {
    if (this.ourConnection) {
      const auth = (({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }) => ({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }))(this.authUser) as CommunityUser;
      this.fullyAcceptConnection({
        uid: this.ourConnection.uid,
        messageLookupArgs: {
          myUid: this.authUser.uid,
          users: [auth, {
            uid: this.uid,
            firstName: this.firstName,
            lastName: this.lastName,
            pictureFileResource: this.pictureFileResource,
          } as CommunityUser],
          groupType: [GroupType.CONNECTION, GroupType.RECONNECTION],
        },
        toastUserFullName: CommunityUser.hydrate(this.ourConnection.user).fullName,
      })
        .then((connection) => {
          if (connection) {
            this.ourConnection = connection;
          }
        });
    }
  }

  private onDecline(): void {
    if (this.ourConnection) {
      const temp = this.ourConnection;
      this.ourConnection = null;
      const auth = (({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }) => ({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }))(this.authUser) as CommunityUser;
      this.fullyDeclineConnection({
        uid: temp.uid,
        messageLookupArgs: {
          myUid: this.authUser.uid,
          users: [auth, {
            uid: this.uid,
            firstName: this.firstName,
            lastName: this.lastName,
            pictureFileResource: this.pictureFileResource,
          } as CommunityUser],
          groupType: [GroupType.CONNECTION, GroupType.RECONNECTION],
        },
        toastUserFullName: CommunityUser.hydrate(temp.user).fullName,
      })
        .catch(() => {
          this.ourConnection = temp;
        });
    }
  }

  // Create or delete a connection
  private toggleConnection(description: string | null = null): void {
    const auth = (({
      uid,
      firstName,
      lastName,
      pictureFileResource,
    }) => ({
      uid,
      firstName,
      lastName,
      pictureFileResource,
    }))(this.authUser) as CommunityUser;
    if (this.ourConnection) {
      const temp = this.ourConnection;
      this.ourConnection = null;
      this.fullyDisconnect({
        uid: temp.uid,
        messageLookupArgs: {
          myUid: this.authUser.uid,
          users: [auth, {
            uid: this.uid,
            firstName: this.firstName,
            lastName: this.lastName,
            pictureFileResource: this.pictureFileResource,
          } as CommunityUser],
          groupType: GroupType.DIRECT,
        },
        toastUserFullName: `${this.firstName} ${this.lastName}`,
      })
        .catch(() => {
          this.ourConnection = temp;
        });
    } else {
      const user = CommunityUser.hydrate({
        uid: this.authUser.uid,
      });
      this.ourConnection = {
        uid: 'added-to-connection',
        linkState: ConnectionStatusType.INVITED,
        user,
      };
      this.fullyConnect({
        connectionArgs: {
          userId: this.authUser.uid,
          linkedUserId: this.uid,
          message: description,
        },
        newConversationArgs: {
          message: description,
          users: [CommunityUser.hydrate({ uid: this.uid }),
            CommunityUser.hydrate({ uid: this.authUser.uid })],
          tempId: '',
          messageTempId: '',
        },
      })
        .then((connection) => {
          if (connection) {
            this.ourConnection = connection;
            this.$logger.logMatomoStats(
              this.authUser,
              this.community.code as string,
              EntityType.USER,
              StatLoggerActions.ADD,
              '',
              -1,
              this.uid,
              StatLoggerCategories.CONNECT,
              this.$i18n.locale,
            );
          }
        })
        .catch(() => {
          this.ourConnection = null;
        });
    }
  }

  private createMeeting(): void {
    const participant = {
      user: {
        uid: this.uid,
        firstName: this.firstName,
        lastName: this.lastName,
        name: this.name,
        jobTitle: this.jobTitle,
        employerName: this.employerName,
        pictureFileResource: this.pictureFileResource,
        schemaCode: this.authUser.schemaCode,
      } as CommunityUser,
    };
    const creator = CommunityUser
      .hydrate(Object.fromEntries(Object.entries(this.authUser)
        .filter(([k]) => [
          'uid',
          'schemaCode',
          'firstName',
          'lastName',
          'name',
          'jobTitle',
          'employerName',
          'pictureFileResource'].includes(k))));
    const starttime = DateTimeHelper
      .roundToNearest15(DateTimeHelper.getCurrentDateTime());
    const endtime = DateTimeHelper
      .roundToNearest15(addHours(DateTimeHelper.getCurrentDateTime(), 1));
    this.setViewMode(ViewMode.CREATE);
    this.setEventEdited({
      creator,
      isCancelled: false,
      participants: [participant as MeetingParticipant],
      tzStartTime: starttime,
      tzEndTime: endtime,
    });
    this.$eventsBus.emit('ontoolbox', { view: ToolbarMenuActions.TOOLBAR_AGENDA });
  }
}
