




















































































































































































import { Component } from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import useTestDataAttribute from '@/utils/TestDataAttribute';
import RouteHelper from '@/utils/helpers/RouteHelper';
import UiPage from '@/models/graphql/UiPage';

const authenticationStore = namespace('AuthenticationStore');
const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  methods: { useTestDataAttribute },
  components: {
    FontAwesomeComponent,
    AvatarSoloWidget,
    ButtonIconComponent,
  },
})
export default class MyProfileMenuComponent extends VueBaseWidget {
  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  protected FeatureKeys = FeatureKeys;

  @permissionManagerStore.Getter
  protected haveACompanyRole!: (companyUid?: string) => boolean;

  @permissionManagerStore.Getter
  protected canModerate!: boolean;

  private FileResourceHelper = FileResourceHelper;

  @authenticationStore.Action
  private logout!: () => void;

  @State
  private readonly unifyDomain!: string;

  @State
  private readonly exhibitorPortalRootPage!: UiPage | null;

  @State
  private readonly exhibitorPortalDomain!: string;

  private get myJobTitleAndCompany(): string {
    let employer = this.authUser.employerName;
    if (this.authUser.defaultCompany) {
      employer = this.authUser.defaultCompany.displayName || this.authUser.defaultCompany.name;
    }
    if (this.authUser.jobTitle) {
      if (employer) {
        return `${this.authUser.jobTitle} ${this.$t('my-profile-menu-component.at')} ${employer}`;
      }
      return `${this.authUser.jobTitle}`;
    }
    if (employer) {
      return `${this.$t('my-profile-menu-component.works')} ${this.$t('my-profile-menu-component.at')} ${employer}`;
    }
    return '';
  }

  private get companyRoles(): CompanyUserRole[] {
    if (this.authUser && this.authUser.companyRoles) {
      const companies = this.authUser.companyRoles;
      return companies.reduce((acc: CompanyUserRole[], cur: CompanyUserRole) => {
        if (acc.findIndex((cmp) => cmp.company?.uid === cur.company?.uid) === -1) {
          acc.push(cur);
        }
        return acc;
      }, []);
    }
    return [];
  }

  private get speakerHubLink(): string {
    if (this.unifyDomain) {
      return RouteHelper.externalNavigationBetweenPortals(this.unifyDomain, 'my-speaker-profile');
    }
    return this.$router.resolve({ name: 'not-found' }).href;
  }

  private get sessionModerationLink(): string {
    if (this.unifyDomain) {
      return RouteHelper.externalNavigationBetweenPortals(this.unifyDomain, 'session-moderation-cms');
    }
    return this.$router.resolve({ name: 'not-found' }).href;
  }

  private getCompanyLink(companyId: string): string {
    if (companyId) {
      if (this.exhibitorPortalDomain && this.exhibitorPortalRootPage && this.exhibitorPortalRootPage.code) {
        return RouteHelper.externalNavigationBetweenPortals(
          this.exhibitorPortalDomain,
          this.exhibitorPortalRootPage.code,
          { companyId },
        );
      }
      if (this.unifyDomain) {
        return RouteHelper.externalNavigationBetweenPortals(this.unifyDomain, 'company-cms', { companyId });
      }
    }
    return this.$router.resolve({ name: 'not-found' }).href;
  }

  private onLogoutClick(): void {
    this.$emit('close-profile-menu');
    this.logout();
    this.$store.commit('AuthenticationStore/mutateJustSignedOut');
  }

  private close(): void {
    (this.$el as HTMLElement).style.overflowY = 'hidden';
    this.$emit('close-profile-menu');
    this.$emit('close');
  }
}
