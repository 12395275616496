

























import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import Session from '@/models/graphql/Session';
import VideoPlayerTheaterComponent from '@/components/video-player/VideoPlayerTheaterComponent.vue';
import { mixins } from 'vue-class-component';
import ChatModuleHelper from '@/components/chat/ChatModuleHelper.vue';
import MessageGroup from '@/models/graphql/MessageGroup';
import GUUID from '@/utils/GUUID';
import GroupType from '@/utils/enums/chat/GroupType';
import EntityType from '@/utils/enums/EntityType';
import MessageBoxActions from '@/utils/enums/chat/MessageBoxActions';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import { Getter } from 'vuex-class';
import CommunityFeature from '@/models/graphql/CommunityFeature';

@Component({
  components: {
    VideoPlayerTheaterComponent,
  },
})
export default class LiveSessionComponent extends mixins(VueBaseWidget, ChatModuleHelper) {
  @Prop({
    required: true,
    default: null,
  })
  private readonly session!: Session;

  @Prop({
    required: false,
    default: '',
  })
  private readonly roundedClass!: string;

  @Prop({
    required: false,
    default: true,
  })
  private readonly canViewVideo!: boolean;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private tempGroupId = GUUID.uuidv4();

  private messageGroup: MessageGroup | null = null;

  private embedded = true;

  mounted(): void {
    this.$nextTick(() => {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      if (params
        && params.has('openTheaterMode')
        && params.get('openTheaterMode') === 'true') {
        this.openTheaterMode();
      }
    });
  }

  created(): void {
    this.storeContext = 'LiveSessionChatStore';
  }

  @Watch('chatStoreCreated')
  init(): void {
    if (this.chatStoreCreated
      && this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE)
      && this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE).enabled
      && this.session.videoType !== 'recorded'
      && this.canViewVideo
      && !!this.session.showLiveChat) {
      this.lookupForMessageGroupWithTarget(this.session.uid)
        .then((group) => {
          if (group) {
            this.setSelectedGroup(group.uid);
            this.changeStatus(MessageBoxActions.MESSAGES);
          } else {
            this.messageGroup = MessageGroup.hydrate({
              uid: this.tempGroupId,
              tempId: this.tempGroupId,
              groupType: GroupType.GROUP,
              users: this.session.moderators || [],
              target: {
                ...this.session,
                __typename: EntityType.SESSION,
              },
              messages: [],
              updated: true,
              selected: true,
            });
            this.setNewConversation(this.messageGroup);
            this.setSelectedGroup(this.messageGroup.uid);
            this.changeStatus(MessageBoxActions.MESSAGES);
          }
        });
    }
  }

  private lookupForMessageGroupWithTarget(targetId: string): Promise<MessageGroup | undefined> {
    return this.$store.dispatch(`${this.storeContextPath}/lookupForMessageGroupWithTarget`, targetId);
  }

  private setNewConversation(group: MessageGroup): void {
    return this.$store.commit(`${this.storeContextPath}/setNewConversation`, group);
  }

  private setSelectedGroup(groupId: string): void {
    this.$store.commit(`${this.storeContextPath}/setSelectedGroup`, groupId);
  }

  private changeStatus(status: MessageBoxActions): void {
    this.$store.commit(`${this.storeContextPath}/changeStatus`, status);
  }

  private openTheaterMode(): void {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    if (params
      && params.has('openTheaterMode')
      && params.get('openTheaterMode') === 'true') {
      window.history.replaceState(null,
        document.title,
        url.href
          .replace(/[?&]openTheaterMode=[^&]+/, '')
          .replace(/^&/, '?'));
    }
    document.body.style.overflow = 'hidden';
    this.embedded = false;
    if (this.$logger) {
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code || '',
        EntityType.SESSION,
        StatLoggerActions.CLICK,
        '',
        this.session?.id ?? -1,
        this.session.uid,
        StatLoggerCategories.LIVE_SESSION_THEATER,
        this.$i18n.locale,
      );
    }
  }

  private closeTheaterMode(): void {
    this.embedded = true;
    document.body.style.overflow = 'auto';
  }
}
