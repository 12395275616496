var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"monthly-calendar"},[_c('div',{staticClass:"calendar"},[_c('div',{staticClass:"calendar-row header"},_vm._l((_vm.daysOfWeek),function(day,index){return _c('div',{key:("d-" + index),staticClass:"calendar-cell border-left-1 border-right-1 border-bottom-1 border-neutral-n-4-line",style:(("width: " + (_vm.cellSize.width)))},[_c('label',{staticClass:"m-0 p-2 label-2 font-weight-normal text-sm-center text-md-left w-100 text-capitalize"},[_vm._v(" "+_vm._s(day)+" ")])])}),0),_vm._l((_vm.calendar),function(week,index){return _c('div',{key:("w-" + index),staticClass:"calendar-row"},_vm._l((week),function(day){return _c('div',{key:day.date,class:['calendar-cell p-0 border-1 border-neutral-n-4-line day-of-month',
                 { 'mobile': _vm.isMobile },
                 { 'text-neutral-n-6-label': !day.isCurrentMonth }],style:(_vm.cellSize),on:{"click":function($event){_vm.isMobile ? _vm.onDayClick(day) : null}}},[_c('div',{class:['content d-flex flex-column justify-content-center align-items-center',
                   'align-items-md-end justify-content-md-end h-100',
                   { 'position-relative': !_vm.isMobile },
                   { 'text-blue-b-3-primary-blue': day.isToday },
                   { 'cursor-pointer': day.meetings && day.meetings.length > 0 },
                   { 'border-1 border-blue-b-3-primary-blue': _vm.isMobile && _vm.selectedDate && _vm.selectedDate.day === day.day }]},[_c('label',{staticClass:"m-0 p-2 label-2 font-weight-normal text-sm-center text-md-right w-100 cursor-pointer"},[_vm._v(" "+_vm._s(day.date)+" ")]),_c('div',{class:['d-flex align-items-center justify-content-center',
                     {'float-meetings p-3 rounded shadows-md bg-white z-index-1 overflow-hidden':
                       !_vm.isMobile && _vm.selectedDate && _vm.selectedDate.day === day.day },
                     _vm.isMobile ? 'day-events' : 'position-absolute top-0 left-0 right-0'],style:(!_vm.isMobile && _vm.selectedDate && _vm.selectedDate.day === day.day
              ? _vm.meetingListPopupStyle
              :''),attrs:{"id":day.day.getTime()}},[(_vm.isMobile)?_vm._l((day.meetings.slice(0, 3)),function(m){return _c('span',{key:m.uid,staticClass:"event"})}):_c('div',{class:['d-flex flex-column w-100 h-100 gap-1',
                       { 'padding-1': !(!_vm.isMobile && _vm.selectedDate && _vm.selectedDate.day === day.day) }]},[(_vm.selectedDate && _vm.selectedDate.day === day.day)?_c('p',{staticClass:"m-0 text-left paragraph-2 font-weight-bold pb-12 d-inline-flex text-neutral-n-8-dark-ink"},[_vm._v(" "+_vm._s(_vm.selectedDay)+" "),_c('font-awesome-component',{attrs:{"container-classes":"border-1 border-neutral-n-4-line rounded-circle icon-24 ml-auto cursor-pointer","icon":"far fa-xmark","icon-classes":"icon-12"},on:{"on-click":function($event){_vm.selectedDate = null}}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column w-100 h-100 gap-1 overflow-auto hide-scrollbar"},_vm._l(((_vm.selectedDate && _vm.selectedDate.day === day.day
                    ? day.meetings
                    : day.meetings.slice(0, _vm.maxMeetingToDisplayPerDay))),function(m){return _c('meeting-slot-component',{key:m.uid,staticStyle:{"overflow":"unset !important"},attrs:{"active":_vm.activeMeeting === m.uid,"meeting":m},on:{"on-click":function($event){return _vm.$emit('open-detail', m.uid)}}})}),1),(!_vm.selectedDate && day.meetings.length - _vm.maxMeetingToDisplayPerDay > 0)?_c('span',{staticClass:"button small font-weight-bold cursor-pointer text-neutral-n-8-dark-ink",on:{"click":function($event){return _vm.onDayClick(day)}}},[_vm._v(" "+_vm._s(_vm.$tc('full-calendar.more', 0, {count: day.meetings.length - _vm.maxMeetingToDisplayPerDay}))+" ")]):_vm._e()])],2)])])}),0)})],2),(_vm.isMobile && _vm.selectedDate && _vm.selectedDate.meetings.length > 0)?_c('div',{staticClass:"day-events-list d-flex flex-column gap-1 p-3"},[_c('p',{staticClass:"m-0 text-left paragraph-2 font-weight-bold pb-12"},[_vm._v(" "+_vm._s(_vm.selectedDay)+" ")]),_vm._l((_vm.selectedDate.meetings),function(m){return _c('meeting-slot-component',{key:m.uid,staticStyle:{"overflow":"unset !important"},attrs:{"meeting":m},on:{"on-click":function($event){return _vm.$emit('open-detail', m.uid)}}})})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }