export const BOOTH_MAP_FRAGMENT = `
  fragment boothMapFragment on Booth {
    uid
    schemaCode
    id
    number
    exhibitHall {
      id
    }
    geozone {
      id
    }
  }
`;
