enum CompanyUserRolePermission {
  MODERATOR = 'moderator',
  CAN_POST = 'canPost',
  MANAGE_SALES = 'manageSales',
  HANDLE_MEETINGS = 'handleMeetings',
  MANAGE_CONTENT = 'manageContent',
  MANAGE_COMPANY_USER_ROLES = 'manageCompanyUserRoles'
}

export default CompanyUserRolePermission;
