













































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import SocialLinksComponent from '@/components/SocialLinksComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import Booth from '@/models/graphql/Booth';
import Exhibitor from '@/models/graphql/Exhibitor';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import ExhibitorUrl from '@/models/graphql/ExhibitorUrl';
import UrlType from '@/utils/enums/UrlType';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { State } from 'vuex-class';
import FormatHelper from '@/utils/helpers/FormatHelper';
import EntityType from '@/utils/enums/EntityType';
import RouteHelper from '@/utils/helpers/RouteHelper';

@Component({
  components: {
    ButtonComponent,
    AvatarSoloWidget,
    FontAwesomeComponent,
    SocialLinksComponent,
    ButtonIconComponent,
  },
})
export default class ProductInfoComponent extends Vue {
  @Prop({
    required: false,
    default: false,
  })
  readonly displayHall!: boolean;

  @Prop({ required: false })
  private exhibitor!: Exhibitor;

  @Prop({ required: false })
  private readonly schemaCode!: string;

  @Prop({ required: false })
  private readonly dimensions!: string;

  @Prop({ required: false })
  private readonly manufacturer!: string;

  @Prop({ required: false })
  private readonly model!: string;

  @Prop({ required: false })
  private readonly serie!: string;

  @Prop({ required: false })
  private readonly serialNumber!: string;

  @Prop({ required: false })
  private readonly price!: number;

  @Prop({ required: false })
  private readonly mapUrl!: string;

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly booths!: Booth[];

  @Prop({ required: false })
  private readonly __typename!: string;

  @Prop({
    required: false,
    default: false,
  })
  private readonly isLargeProduct!: boolean;

  @Prop({ required: false })
  private readonly dataType!: string;

  @Prop({
    required: false,
    default: '',
  })
  private readonly classList!: string;

  @State
  private dateLocale!: Locale;

  private FileResourceHelper = FileResourceHelper;

  get showInfo(): boolean {
    if (this.isLargeProduct) {
      return ((this.dataType === 'price' && !!this.price)
        || (this.dataType === 'details' && this.hasMoreData)
        || (this.dataType === 'location' && this.booths.length > 0)
        || (this.dataType === 'exhibitor' && !!this.exhibitor));
    }
    if (this.hasMoreData) {
      return true;
    }
    return this.booths.length > 0 && !this.isLargeProduct;
  }

  private get fieldsData(): object[] {
    const result = [];
    if (this.manufacturer) {
      result.push({
        translation: this.$t('entity-info.product.manufacturer'),
        value: this.manufacturer,
      });
    }
    if (this.model) {
      result.push({
        translation: this.$t('entity-info.product.model'),
        value: this.model,
      });
    }
    if (this.serie) {
      result.push({
        translation: this.$t('entity-info.product.serie'),
        value: this.serie,
      });
    }
    if (this.serialNumber) {
      result.push({
        translation: this.$t('entity-info.product.serialNumber'),
        value: this.serialNumber,
      });
    }
    if (this.dimensions) {
      result.push({
        translation: this.$t('entity-info.product.dimensions'),
        value: this.dimensions,
      });
    }
    if (this.price !== null) {
      result.push({
        translation: this.$t('entity-info.product.price'),
        value: this.priceTag(),
      });
    }
    return result;
  }

  private get hasMoreData(): boolean {
    return (this.dimensions && this.dimensions !== '')
      || (this.manufacturer && this.manufacturer !== '')
      || (this.model && this.model !== '')
      || (this.serie && this.serie !== '')
      || (this.serialNumber && this.serialNumber !== '')
      || this.price !== null;
  }

  private get exhibitorPhone(): ExhibitorUrl | null {
    if (this.isLargeProduct && this.dataType === 'exhibitor') {
      return this.exhibitor.urls?.filter((url) => {
        const type = url.type?.toLowerCase();
        return type === UrlType.PHONE;
      })[0] as ExhibitorUrl;
    }
    return null;
  }

  private get exhibitorWebsites(): ExhibitorUrl[] | null {
    if (this.isLargeProduct && this.dataType === 'exhibitor') {
      const websites = this.exhibitor.urls?.filter((url) => {
        const type = url.type?.toLowerCase();
        return type === UrlType.WEBSITE || type === null;
      }) as ExhibitorUrl[];
      return websites?.length > 0 ? websites.reverse() : null;
    }
    return null;
  }

  private get productBooth(): string {
    const parts: string[] = [];
    if (this.isLargeProduct && this.booths.length > 0) {
      if (this.displayHall
        && this.booths[0].exhibitHall
        && this.booths[0].exhibitHall.name) {
        parts.push(`${this.booths[0].exhibitHall.name}`);
        parts.push(`${this.$t('entity-info.large-product.booth')} ${this.booths[0].number}`);
      } else {
        parts.push(`${this.$t('entity-info.large-product.booth')} ${this.booths[0].number}`);
      }
    } else if (!this.isLargeProduct
      && this.exhibitor
      && this.exhibitor.editionMappings
      && this.exhibitor.editionMappings.length > 0
      && this.exhibitor.editionMappings[0]
      && this.exhibitor.editionMappings[0].editionExhibitor
      && this.exhibitor.editionMappings[0].editionExhibitor.booths
      && this.exhibitor.editionMappings[0].editionExhibitor.booths.length > 0
    ) {
      const { booths } = this.exhibitor.editionMappings[0].editionExhibitor;
      if (this.displayHall
        && booths[0].exhibitHall
        && booths[0].exhibitHall.name) {
        parts.push(`${booths[0].exhibitHall.name}`);
        parts.push(`${this.$t('entity-info.product.booth')} ${booths[0].number}`);
      } else {
        parts.push(`${this.$t('entity-info.product.booth')} ${booths[0].number}`);
      }
    }
    return parts.join(' · ');
  }

  private priceTag(): string {
    return `${FormatHelper.formatCurrency(this.price)}`;
  }

  private onVisitExhibitorClick(): void {
    if (this.exhibitor) {
      RouteHelper.internalDetailRouteNavigation(
        this.$router,
        this.exhibitor.uid,
        EntityType.EXHIBITOR,
      );
    }
  }

  private onExhibitorWebsiteClick(): void {
    if (this.exhibitorWebsites && this.exhibitorWebsites.length > 0) {
      const website = this.exhibitorWebsites[0];
      window.open(website.url, '_blank');
    }
  }

  private openMap(): void {
    let geozones: string[] = [];
    if (this.isLargeProduct && this.booths.length > 0 && this.booths[0].exhibitHall?.id) {
      geozones = this.booths.map((b) => `${b.geozone?.id}`) as string[];
    }
    const routeMap = `${this.mapUrl}?edition=${this.schemaCode}`
      + `&hall=${this.booths[0].exhibitHall?.id}`
      + `&${geozones.map((g, index) => `filters[geozones][${index}]=${g}`)
        .join('&')}`;
    this.$router.push(routeMap);
  }
}
