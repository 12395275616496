


































































import { Component, Prop } from 'vue-property-decorator';
import StandardModal from '@/components/modals/StandardModal.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import CheckboxSwitchComponent from '@/components/CheckboxSwitchComponent.vue';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import { Getter, namespace } from 'vuex-class';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PermissionsManagerForm from '@/components/modals/PermissionsManagerForm.vue';
import { CommunityUserPermissions } from '@/utils/types/CommunityUserPermissions';
import CompanyRoleEnum from '@/utils/enums/CompanyRoleEnum';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

const companyUserRoleStore = namespace('CompanyUserRoleStore');
const chatStore = namespace('ChatDispatcherStore/ChatStore');

@Component({
  components: {
    FontAwesomeComponent,
    ButtonIconComponent,
    PermissionsManagerForm,
    ButtonComponent,
    CheckboxSwitchComponent,
    StandardModal,
  },
})
export default class PermissionsManagerModal extends BreakpointWrapper {
  @chatStore.Action
  messageGroupLookup!: (payload: {
    myUid: string;
    users: CommunityUser[];
    groupType: string[];
    targetUid?: string;
  }) => Promise<string | null>;

  @chatStore.Action
  toggleCompanyChatModerator!: (payload: {
    user: string;
    company: string;
    toAdd: boolean;
  }) => Promise<void>;

  @Prop({ default: '' })
  private modalId!: string;

  @Prop({ required: true })
  private communityUser!: CommunityUser;

  @Prop({ required: true })
  private companyId!: string;

  @Getter
  private readonly authUser!: CommunityUser;

  @companyUserRoleStore.Action
  private setCompanyUserPermissions!: (payload: CommunityUserPermissions) => Promise<void | CompanyUserRole | undefined>;

  private permissions!: CommunityUserPermissions;

  private isLoading = false;

  private get canEditPermissions(): boolean {
    return this.communityUser
      && !!(this.communityUser.roleInCompany
        && this.communityUser.roleInCompany.company
        && this.communityUser.roleInCompany.company.uid)
      && this.authUser
      && this.authUser.uid !== this.communityUser.uid
      && !!this.authUser.companyRoles
        .find((cr) => cr.company?.uid === this.communityUser.roleInCompany.company?.uid
          && ((cr.role.code === CompanyRoleEnum.OWNER && this.communityUser.uid !== this.authUser.uid)
            || !!cr.manageCompanyUserRoles));
  }

  private get authUserPermissions(): CompanyUserRole | undefined {
    return this.authUser.companyRoles.find((cr) => cr.company?.uid === this.companyId);
  }

  private onHide(): void {
    this.$emit('on-modal-hide');
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
  }

  private onSubmit(): void {
    this.isLoading = true;
    this.setCompanyUserPermissions(this.permissions)
      .then(() => {
        this.toggleCompanyChatModerator({
          user: this.communityUser.uid,
          company: this.communityUser.roleInCompany.company?.uid as string,
          toAdd: !!this.permissions.moderator,
        });
      });
    this.$emit('on-submit', this.permissions);
    this.isLoading = false;
    this.$bvModal.hide(this.modalId);
  }
}
